<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import router from "@/router";
import { HTTP } from "@/main-source";
import Swal from "sweetalert2";
/**
 * Starter page component
 */
export default {
  page: {
    meta: [{ name: "List Of Users" }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      xhrRequest: true,
      list: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    newInvestor: function () {
      router.push({ name: "new-investor" });
    },
    getData: function () {
      this.list = [];
      let uri = "firms";

      HTTP.get(uri)
        .then((result) => {
          this.list = result.data.rows;
          this.xhrRequest = false;
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title:this.t("setup.gerr"),
            showConfirmButton: false,
            timer: 1500,
          });
          this.xhrRequest = false;
        });
    },
  },
  watch: {
    "$route.name": function () {
      this.getData();
    },
  },
  computed: {
    pageTitle: function () {
      return this.$route.name == "users" ? "Users" : "Companies";
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title='$t("setup.company")'/>
    <div class="process-row">
      <router-link to="/companies/new" class="btn btn-success">
        + {{ $t("setup.addnew") }}
      </router-link>
    </div>

    <div class="row" :class="{ waiting: xhrRequest }">
      <div class="spinner-border text-success" role="status" v-if="xhrRequest">
        <span class="sr-only">{{ $t("setup.addnew") }}</span>
      </div>
      <div class="d-flex firm-lists w-100" v-if="xhrRequest == false">
        <div class="col-sm-2" v-for="(item, key) in list" :key="key">
          <router-link
            :to="{
              name: 'company-profile',
              params: {
                id: item.id,
              },
            }"
          >
            <b-card
              :img-src="
                item.profileImg
                  ? $filesPath + item.profileImg
                  : require('@/assets/images/no-image.jpg')
              "
              img-alt="Card image"
              img-top
            >
              <b-card-text class="text-center"> {{ item.name }} </b-card-text>
            </b-card>
          </router-link>
        </div>
      </div>
    </div>
  </Layout>
</template>