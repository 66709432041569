var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.$t("setup.company")}}),_c('div',{staticClass:"process-row"},[_c('router-link',{staticClass:"btn btn-success",attrs:{"to":"/companies/new"}},[_vm._v(" + "+_vm._s(_vm.$t("setup.addnew"))+" ")])],1),_c('div',{staticClass:"row",class:{ waiting: _vm.xhrRequest }},[(_vm.xhrRequest)?_c('div',{staticClass:"spinner-border text-success",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t("setup.addnew")))])]):_vm._e(),(_vm.xhrRequest == false)?_c('div',{staticClass:"d-flex firm-lists w-100"},_vm._l((_vm.list),function(item,key){return _c('div',{key:key,staticClass:"col-sm-2"},[_c('router-link',{attrs:{"to":{
            name: 'company-profile',
            params: {
              id: item.id,
            },
          }}},[_c('b-card',{attrs:{"img-src":item.profileImg
                ? _vm.$filesPath + item.profileImg
                : require('@/assets/images/no-image.jpg'),"img-alt":"Card image","img-top":""}},[_c('b-card-text',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)],1)}),0):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }